<template>
    <div style="background-color:black;">
        <div class="app bgImage animate__animated animate__fadeIn">

            <div id="step1" class="wizard1" v-if="step==1">
                
                <div>

                    <div class="componente1 vert-move"></div>
                    <div class="sub_componente1 vert-move"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra" style="font-size: 35px "> <b>Selecciona el app que necesitas</b></p>
                                <div class="row ">
                                    <div class="col-md-3 "> 
                                        <img class="apps mt-3 mb-2 img-fluid" @click="goToStep(2)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">Comida a domicilio</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2 img-fluid" @click="goToStep(2)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">Envíos</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2 img-fluid" @click="goToStep(2)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">E-commerce</b>
                                    </div>
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2 img-fluid" @click="goToStep(2)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">App personalizada</b>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
                
            <div id="step2" class="wizard1" v-if="step==2 && rows.length>0">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[0].title}}</b></p>
                                <div class="row margen-row2">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen img-fluid" @click="goToStep(3, rows[2].id, rows[0].title, rows[2].description, rows[2].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[2].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen img-fluid" @click="goToStep(3, rows[0].id, rows[0].title, rows[0].description, rows[0].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[0].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen img-fluid" @click="goToStep(3, rows[1].id, rows[0].title, rows[1].description, rows[1].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[1].description}} </b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step3" class="wizard1" v-if="step==3">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra" style="font-size: 35px "> <b>{{rows[3].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-3 "> 
                                        <img class="apps mt-3 mb-2" @click="goToStep(4, rows[4].id, rows[3].title, rows[4].description, rows[4].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[4].description}}</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(4, rows[5].id, rows[3].title, rows[5].description, rows[5].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[5].description}}</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(4, rows[3].id, rows[3].title, rows[3].description, rows[3].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[3].description}}</b>
                                    </div>
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(4, rows[6].id, rows[3].title, rows[6].description, rows[6].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[6].description}}</b>
                                    </div> 
                                </div> 
                            
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <div id="step4" class="wizard1" v-if="step==4">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[7].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen " @click="goToStep(5, rows[7].id, rows[7].title, rows[7].description, rows[7].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[7].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(5, rows[8].id, rows[7].title, rows[8].description, rows[8].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[8].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(5, rows[9].id,rows[7].title, rows[9].description, rows[9].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[9].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step5" class="wizard1" v-if="step==5">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra" style="font-size: 35px "> <b>{{rows[10].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-3 "> 
                                        <img class="apps mt-3 mb-2" @click="goToStep(6, rows[10].id, rows[10].title, rows[10].description, rows[10].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[10].description}}</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(6, rows[11].id, rows[10].title, rows[11].description, rows[11].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[11].description}}</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(6, rows[12].id, rows[10].title, rows[12].description, rows[12].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[12].description}}</b>
                                    </div>
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(6, rows[13].id, rows[10].title, rows[13].description, rows[13].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[13].description}}</b>
                                    </div> 
                                </div> 
                            
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <div id="step6" class="wizard1" v-if="step==6">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[14].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen " @click="goToStep(7, rows[14].id, rows[14].title, rows[14].description, rows[14].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[14].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(7, rows[15].id, rows[14].title, rows[15].description, rows[15].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[15].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(7, rows[16].id,rows[14].title, rows[16].description, rows[16].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[16].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step7" class="wizard1" v-if="step==7">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[17].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen " @click="goToStep(8, rows[17].id, rows[17].title, rows[17].description, rows[17].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[17].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(8, rows[18].id, rows[17].title, rows[18].description, rows[18].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[18].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(8, rows[19].id,rows[17].title, rows[19].description, rows[19].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[19].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step8" class="wizard1" v-if="step==8">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[20].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen " @click="goToStep(9, rows[20].id, rows[20].title, rows[20].description, rows[20].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[20].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(9, rows[21].id, rows[20].title, rows[21].description, rows[21].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[21].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen " @click="goToStep(9, rows[22].id, rows[22].title, rows[22].description, rows[22].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[22].description}} </b>
                                    </div>
                                </div> 
                            
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step9" class="wizard1" v-if="step==9">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[23].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen "  @click="goToStep(10, rows[23].id, rows[23].title, rows[23].description, rows[23].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[23].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(10, rows[24].id, rows[23].title, rows[24].description, rows[24].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[24].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(10, rows[25].id, rows[23].title, rows[25].description, rows[25].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[25].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step10" class="wizard1" v-if="step==10">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra" style="font-size: 35px "> <b>{{rows[26].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-3 "> 
                                        <img class="apps mt-3 mb-2" @click="goToStep(11, rows[26].id, rows[26].title, rows[26].description, rows[26].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[26].description}}</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(11, rows[27].id, rows[26].title, rows[27].description, rows[27].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[27].description}}</b>
                                    </div> 
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(11, rows[28].id, rows[26].title, rows[28].description, rows[28].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[28].description}}</b>
                                    </div>
                                    <div class="col-md-3 ">
                                        <img class="apps mt-3 mb-2" @click="goToStep(11, rows[29].id, rows[26].title, rows[29].description, rows[29].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[29].description}}</b>
                                    </div> 
                                </div> 
                            
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <div id="step11" class="wizard1" v-if="step==11">
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[30].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen "  @click="goToStep(12, rows[30].id, rows[30].title, rows[30].description, rows[30].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[30].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(12, rows[31].id, rows[30].title, rows[31].description, rows[31].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[31].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(12, rows[32].id, rows[30].title, rows[32].description, rows[32].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[32].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>

            <div id="step12" class="wizard1" v-if="step==12">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[33].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen "  @click="goToStep(13, rows[33].id, rows[33].title, rows[33].description, rows[33].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[33].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(13, rows[34].id, rows[33].title, rows[34].description, rows[34].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[34].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(13, rows[35].id, rows[33].title, rows[35].description, rows[35].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[35].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
            <div id="step12" class="wizard1" v-if="step==13">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                    <p class="  color_letra mb-4" style="font-size: 35px "> <b>{{rows[36].title}}</b></p>
                                <div class="row ">
                                    <div class="col-md-4 "> 
                                        <img class="apps2 margen "  @click="goToStep(14, rows[36].id, rows[36].title, rows[36].description, rows[36].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[36].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(14, rows[38].id, rows[36].title, rows[38].description, rows[38].price)" src="../assets/componente_2.png" alt=""> <br><b class="color_letra_2">{{rows[38].description}}</b>
                                    </div> 
                                    <div class="col-md-4 ">
                                        <img class="apps2 margen "  @click="goToStep(14, rows[37].id, rows[36].title, rows[37].description, rows[37].price)" src="../assets/componente_4.png" alt=""> <br><b class="color_letra_2">{{rows[37].description}}</b>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
            <div id="step14" class="wizard1" v-if="step==14">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row " >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra3 mb-4" > <b>Dejanos tus datos</b></p>
                                <div class="input mb-3">
                                    <input v-model="name" required type="text" class="form-control tamaño_input sombra p-4 bg-light rounded" placeholder="Nombre completo" aria-label="Username" aria-describedby="basic-addon1">
                                </div>
                                <div class="input mb-3">
                                    <input v-model="whatsapp" required type="text" class="form-control tamaño_input sombra p-4 bg-light rounded" placeholder="Whatsapp" aria-label="whatsapp" aria-describedby="basic-addon1" >
                                </div>
                                <div class="input mb-3">
                                    <input v-model="email" required type="text" class="form-control tamaño_input sombra p-4 bg-light rounded" placeholder="E-mail" aria-label="email" aria-describedby="basic-addon1">
                                </div>
                                <button class="btn color-bb2" @click="goToStep(15)">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <div id="step15" class="wizard1" v-if="step==15">
                
                <div>

                    <div class="componente1"></div>
                    <div class="sub_componente1"></div> 

                    <div class="container-fluid top">
                        <div class="row text-center" >
                            <div class="col-md-8 offset-md-2 color_back" >
                                <h1 class="color_letraL"> <b>¡Listo!</b></h1>
                                <p class="color_letra_ mt-3"> <b>${{sumaTotal.toFixed(2)}} mnx</b></p>
                                <p class="color_letra2"> Este es el costo aproximado para el desarrollo. Nos gusta platicar más acerca de tus necesidades y ver como podemos ayudarte de la mejor forma</p>
                                
                                <div class="row">       
                                    <div class="col-md-6">
                                        <a target="_blank" :href="'https://wa.me/' + config.data"><button class="btn color-bb" @click="goToStep(15)">Hablar con un asesor</button></a>
                                        
                                    </div>
                                    <div class="col-md-6">
                                        <b-button @click="gotoPdf" variant="danger" class="mb-2 button-2">
                                            PDF <b-icon icon="download" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </div>
                                </div>      

                            </div>
                        </div>
                    </div>
                </div> 
            </div>

        </div>
    </div>

</template>

<script>

export default {
 name: 'wizard',
  components: {
    
  },
    data(){
        return{
            step: 2,
            rows: [],
            wizardResult:[],
            name: '',
            whatsapp: '',
            email: '',
            sumaTotal: 0,
            config: [],
        }

    },
  async mounted(){
      this.getData();
      this.getConfig();
  },
  methods:{
      async getConfig(){
          try {
              let req = await fetch(this.$store.state.url + "config/1")
              let config = await req.json()
              this.config = config
              console.log('rr', this.config)
              //this.whatsapp = this.config.data
          } catch (error) {
              console.log('error', error)
          }
      },
    gotoPdf(){
        localStorage.setItem('pdf', JSON.stringify(this.wizardResult))
        this.$router.push('/pdf')
    },
    async getData(){
        let req = await fetch(this.$store.state.url + "wizardone")
        let rows = await req.json()
        this.rows = rows
        console.log('rr', this.rows)
    },   
    async goToStep(item, id, title, description, price){
        let data
        if(item != 15){
            data = {
                id: id, title: title, description: description, price: price
            }
        }
        else{
            if(this.name == '' || this.whatsapp == '' || this.email == ''){
                alert('Debes ingresar la información requerida.');
                return;
            }
            else{
                data = {
                    nombre: this.name,
                    whatsapp : this.whatsapp,
                    email: this.email
                }
                this.sumaTotal = 0;
                for await (let element of this.wizardResult){
                    this.sumaTotal = this.sumaTotal + element.price
                }
            }
        }
        this.wizardResult.push(data)
        console.log(this.wizardResult)
        this.step=item
    },
  }
}
</script>
<style scoped>
.bgImage{
    background-image: url('../assets/Inicio/imgfond.png');
    
  
  max-width: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wizard1{
  display:flex ;
  height: 750px;
  position: relative;
}

/* .componente1{
  background-image: url("../assets/componente_1.png");
  width:275px;
  height:250px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;

  
} 
.sub_componente1{
  background-image: url("../assets/componente_1.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width:275px;
  height:250px;
  right:0px ;
  top: 500px;
  transform: rotate(180deg);
} */

.apps{
  max-height:70% ;
  max-width: 80%;
}
.top{
  position: absolute;
  top: 20%;
}
.color_back{
  background-color: #1673ffde;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50%;
  margin-top: 5%;      
  }
.color_letra{
  color: #ffffff;
  text-align: left;
  font-size:35px;
  margin-left: 3%;
}

.color_letra_2{
  color: #ffffff;
  font-size: 20px;
}
.color_letra3{
  color: #ffffff;
  text-align: center;
  font-size:35px;
}
.margen{
    margin-bottom: 3%;
}
.apps2{
    max-height:80% ;
    max-width: 60%;
}
.apps3{
    max-height:80% ;
    max-width: 70%;
   
}

.color_back3{
  background-color: #1673ffde;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 100%;
  margin-top: 5%;      
  }
.top3{
  position: absolute;
  top: 20%;
  }
  .margen-row{
    margin-left: 20%;
    margin-right: 20%;
  }
  .margen-row-2{
    margin-left: 20%;
    margin-right: 20%;
  }
  .color-bb{
      background-color: rgb(4, 182, 34);
      color: rgb(242, 212, 251);
      border-radius: 18px;
      width: 90%;
      height: 70px;
      font-size: 25px;
  }
  .color-bb:hover{
      background-color: rgb(6, 175, 20);
      color: rgb(255, 255, 255);
  }
  .color_letra_{
  color: #ffffff;
  text-align: center;
  font-size:45px;
}

.color_letra2{
  color: #ffffff;
  font-size: 28px;
  text-align: left;
  margin-left: 70px;
  margin-right: 70px;
}
.color-bb2{
      background-color: rgb(174, 36, 202);
      color: rgb(242, 212, 251);
      border-radius: 18px;
      width: 400px;
      height: 70px;
      font-size: 25px;
  }
  .color-bb2:hover{
      background-color: rgb(156, 24, 182);
      color: rgb(255, 255, 255);
  }
  .tamaño_input{
    width:70%;
    margin-left: 15%;
    margin-right: 15%;
    height: 5%;
}
.sombra{
      box-shadow: 0 1px 1px 0 rgba(109, 115, 124, 0.198), 0 0px 7px 0 rgba(45, 112, 255, 0.19);
}
.button-2{
    border-radius: 15px;
      width: 40%;

      padding:5px;
      height: 70px;
}
.vert-move:hover {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
 h1{
            
            color: rgb(255, 255, 255);
        }

@-webkit-keyframes mover {
    0% { transform: translateX(0); }
    100% { transform: translateX(-30px); }
}

@media only screen and (max-width: 780px) {
    .button-2{
        border-radius: 15px;
        width: 40%;
        padding-left:5px;
        padding-right:5px;
        height: 50px;
    }
        h1{
            font-size: 3rem !important;
            color: rgb(255, 255, 255);
        }
    .bgImage{
        background-image: url('../assets/Inicio/imgfond.png'); 
        height: 1300px;
        max-width: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        }
    .apps{
        max-height:55% ;
        max-width: 65%;
        margin-top: 0%;
    }
    .sub_componente1{
      display: none;

    }
    .color_back{
    background-color: #1673ffde;
    border-radius: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5% ;
    }
    .color_letra {
      text-align: center;
    }
    .tamaño_input{
    width: 280px;
    margin-left: 5%;
    margin-right: 5%;
    height: 5%;
    }
    .color-bb, .color-bb2{
        width: 250px;
        height: 50px;
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .color_letra2{
    color: #ffffff;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    } 



}


</style>